<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div slot="header">
      <h5>
        {{ $route.meta.title }}
        <template v-if="detail">
          <span class="text-info ml-20">已认证</span>

          <router-link
            :to="'/org/detail/' + detail.id"
            target="_blank"
            class="ml-20 text-success"
            >机构主页</router-link
          >
        </template>
        <template v-else>
          <span class="text-gray-6 ml-20">未认证</span>
        </template>
      </h5>
    </div>
    <div class="text-warning">
      认证成功后，修改信息需要等待重新认证才可以覆盖原来信息;
    </div>

    <div class="border-top mt-20 pt-10" v-if="!detail && !certifiedLog">
      <el-button type="info" @click="attestDialogVisible = true"
        >立即认证</el-button
      >

      <ul class="mt-20">
        <li>认证后可以用户获得V标识</li>
        <li>认证后可以开通机构主页</li>
      </ul>
    </div>
    <div class="border-top mt-20 pt-10" v-if="detail">
      <h6>
        已认证信息
        <span
          v-if="!certifiedLog"
          class="pointer text-info ml-20"
          @click="attestDialogVisible = true"
          >修改</span
        >
      </h6>
      <div class="info-item">
        <div class="info-item-label"></div>
        <div>
          <el-avatar
            class="pofile-avatar mt-20"
            :src="
              detail.logo
                ? viewImg(detail.logo)
                : require('@/assets/images/unknow.png')
            "
          ></el-avatar>
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">机构名称</div>
        <div>
          {{ detail.name }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">机构介绍</div>
        <div>
          {{ detail.description }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">联系人</div>
        <div>
          {{ detail.contact }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">联系电话</div>
        <div>
          {{ detail.phone }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">认证时间</div>
        <div>
          {{ detail.createTime }}
        </div>
      </div>
    </div>
    <div class="border-top mt-20 pt-10" v-if="certifiedLog">
      <h6>
        待认证信息
        <span
          class="pointer text-info ml-20"
          @click="attestDialogVisible = true"
          >修改</span
        >
      </h6>
      <div class="info-item">
        <div class="info-item-label"></div>
        <div>
          <el-avatar
            class="pofile-avatar mt-20"
            :src="
              certifiedLog.logo
                ? viewImg(certifiedLog.logo)
                : require('@/assets/images/unknow.png')
            "
          ></el-avatar>
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">机构名称</div>
        <div>
          {{ certifiedLog.name }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">机构介绍</div>
        <div>
          {{ certifiedLog.description }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">联系人</div>
        <div>
          {{ certifiedLog.contact }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-label">联系电话</div>
        <div>
          {{ certifiedLog.phone }}
        </div>
      </div>

      <div class="info-item">
        <div class="info-item-label">认证状态</div>
        <div>
          <span class="text-info" v-if="!certifiedLog.status">认证中</span>
          <span class="text-success" v-if="certifiedLog.status == 1">失败</span>
          <span class="text-warning" v-if="certifiedLog.status == 2">失败</span>
        </div>
      </div>

      <div class="info-item">
        <div class="info-item-label">原因</div>
        <div
          v-if="certifiedLog.remark"
          v-html="$utils.html2content(certifiedLog.remark)"
        ></div>
        <div v-else>未反馈</div>
      </div>
      <div class="info-item">
        <div class="info-item-label">动态变更时间</div>
        <div>
          {{ certifiedLog.createTime }}
        </div>
      </div>
    </div>

    <el-dialog
      title="申请认证"
      :visible.sync="attestDialogVisible"
      append-to-body
      width="40%"
    >
      <div v-if="!step">
        <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item class="mt-20" label="机构名称" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入名称"
              :maxlength="100"
              show-word-limit
              clearable
              :style="{ width: '100%' }"
            ></el-input>
            <div class="text-warning"></div>
          </el-form-item>
          <el-form-item class="mt-20" label="联系人" prop="contact">
            <el-input
              v-model="formData.contact"
              placeholder="请输入联系人"
              :maxlength="100"
              show-word-limit
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>

          <el-form-item class="mt-20" label="联系电话" prop="phone">
            <el-input
              v-model="formData.phone"
              placeholder="请输入联系电话"
              :maxlength="100"
              :style="{ width: '100%' }"
            ></el-input>
            <div>认证期间请确保电话畅通</div>
          </el-form-item>

          <el-form-item class="mt-20" label="介绍" prop="description">
            <el-input
              v-model="formData.description"
              placeholder="机构介绍"
              :maxlength="200"
              show-word-limit
              type="textarea"
              clearable
              autosize
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div
        v-if="step == 1"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <el-avatar
          class="pofile-avatar pointer"
          @click="logoDialogVisible = true"
          :src="
            formData.logo
              ? viewImg(formData.logo)
              : require('@/assets/images/unknow.png')
          "
        ></el-avatar>
        <el-button
          type="primary"
          class="mt-20"
          size="small"
          @click="logoDialogVisible = true"
          >上传机构徽标</el-button
        >
      </div>
      <span slot="footer">
        <el-button @click="attestDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm" v-if="step === 0"
          >下一步</el-button
        >
        <el-button
          type="primary"
          @click="attestDialogVisible = false"
          v-if="step == 1"
          >完成</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="上传头像"
      :visible.sync="logoDialogVisible"
      width="60%"
      append-to-body
    >
      <div class="d-flex justify-content-center align-items-center flex-column">
        <ImageCroper
          ref="imageCroper"
          v-if="logoDialogVisible"
          @success="uploadImage"
          :ratio="1 / 1"
          round
        ></ImageCroper>
        <el-button type="primary" @click="cropImage">上传</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import ImageCroper from "@/components/imageCroper";
export default {
  components: { ImageCroper },
  data() {
    return {
      step: 0,
      logoDialogVisible: false,
      attestDialogVisible: false,
      formData: {
        logo: "",
        name: "",
        contact: "",
        phone: "",
        description: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入机构联系人",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入机构联系电话",
            trigger: "blur",
          },
        ],
      },
      detail: null,
      certifiedLog: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    getDetail() {
      this.$api.ucenter.Organization.detail().then((res) => {
        this.detail = res.data;

        if (res.data) {
          this.formData = res.data;
        }
        this.getCertifiedLog();
      });
    },
    getCertifiedLog() {
      this.$api.ucenter.Organization.certifiedLog().then((res) => {
        if (res.data) {
          this.formData = res.data;
        }
        this.certifiedLog = res.data;
      });
    },
    cropImage() {
      this.$refs.imageCroper.crop();
    },
    uploadImage(file) {
      if (!file) {
        return;
      }
      let param = new FormData(); //创建form对象
      param.append("attach", file, "crop.jpg"); //通过append向form对象添加数据

      this.$api.ucenter.Organization.logo(param).then((res) => {
        this.certifiedLog = res.data;
        this.formData = res.data;
        this.logoDialogVisible = false;
      });
    },

    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;

        // TODO 提交表单

        this.$api.ucenter.Organization.certifiedLogSave(this.formData)
          .then((res) => {
            this.certifiedLog = res.data;
            this.formData = res.data;
            this.step = 1;
          })
          .catch((err) => {
            console.log("err:", err);
          });
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.pofile-avatar {
  width: 100px;
  height: 100px;
}
.info-item {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  &-label {
    width: 120px;
    text-align: right;
    padding-right: 10px;
    color: #999;
  }
}
</style>
